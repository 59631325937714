/* eslint-disable no-unused-vars */
import { createStore, createLogger } from "vuex";
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import date from 'quasar/src/utils/date.js';;
import bac from "../store/bac/index";
import ApiService from "../plugins/axios";
import { echo } from "../plugins/websockets";
import { map_products } from "../plugins/products";
import { send_message } from "../plugins/message_handler";
import { cc_Enabled, cc_GotoPage } from "../plugins/cc-integration.js";

var listening = false;
var listeningRecordId = 0;

const initialState = {
  error: {
    show: false,
    message: "",
  },
  url: "",
  viewMode: false,
  view: "energyportal",
  manualEan: false,
  searchedAddress: false,
  loading: {},
  poll: {},
  pollLimit: {
    start_bac_flow: 2,
  },
  record: {},
  landingContent: {},
  projectSettings: {},
  selectedProducts: [],
  emailValid: true,
  ibanValid: false,
  products: {},
  startDate: {},
  projectSpecific: {},
  steamVelden: {},
  d2d: "",
  expandedTool: "",
  pendingRequests: 0,
  voicelog: "",
  component_settings: {},
  status: [],
  startDateObj: {},
  compareOfferWait: null,
  toggleTQIS: false,
  redoAll: false,
  message: "",
};

const store = createStore({
  state: initialState,
  mutations: {
    setRecord: (state, record) => {
      state.record = record;
    },
    setComponentSettings: (state, component_settings) => {
      state.component_settings = component_settings;
    },
    setProducts: (state, products) => {
      state.products = products;
    },
    setSelectedProducts: (state, selectedProducts) => {
      state.selectedProducts = selectedProducts;
    },
    setUrl: (state, url) => {
      state.url = url;
    },
    setViewMode: (state, viewMode) => {
      state.viewMode = viewMode;
    },
    setView: (state, view) => {
      state.view = view;
    },
    setPendingRequests: (state, pendingRequests) => {
      state.pendingRequests = pendingRequests;
    },
    setLoading: (state, { key, value }) => {
      state.loading[key] = value;
    },
    setManualEan: (state, manualEan) => {
      state.manualEan = manualEan;
    },
    setProjectSpecificValue: (state, { key, value }) => {
      let projectSpecific = JSON.parse(state.record.Project_Specific);
      projectSpecific[key] = value;
      state.record.Project_Specific = JSON.stringify(projectSpecific);
    },
    clearProjectSpecificValue: (state, { key }) => {
      let projectSpecific = JSON.parse(state.record.Project_Specific);
      projectSpecific[key] = null;
      state.record.Project_Specific = JSON.stringify(projectSpecific);
    },
    updateRecord: (state, { key, value }) => {
      state.record[key] = value;
    },
    setCurrentStep: (state, currentStep) => {
      state.record.currentStep = currentStep;
    },
    setExpandedTool: (state, expandedTool) => {
      state.expandedTool = expandedTool;
    },
    setError: (state, error) => {
      state.error.show = error.show;
      state.error.message = error.message;
    },
    setStartDates: (state, startDate) => {
      state.startDate.electricity = startDate;
      state.startDate.gas = startDate;
    },
    setPoll: (state, { name }) => {
      state.poll[name] = {
        name,
      };
    },
    setSearchedAddress: (state, value) => {
      state.searchedAddress = value;
    },
    setEmailValid: (state, result) => {
      state.emailValid = result;
      if (result == null) {
        state.emailValid = false;
      } else if (result == true) {
        Notify.create({
          type: "positive",
          message: "Dit lijkt een valide e-mailadres te zijn!",
          position: "top",
        });
      } else if (result == false) {
        Notify.create({
          type: "negative",
          message: "Dit lijkt een verkeerd e-mailadres te zijn.",
          position: "top",
        });
      }
    },
    setIbanValid: (state, iban) => {
      state.ibanValid = iban;
      if (iban == null) {
        state.ibanValid = false;
      } else if (iban) {
        state.ibanValid = true;
        Notify.create({
          type: "positive",
          message: "Dit lijkt een valide iban te zijn!",
          position: "top",
        });
      } else if (iban == false) {
        Notify.create({
          type: "negative",
          message: "Dit lijkt een verkeerde iban te zijn.",
          position: "top",
        });
      }
    },
    deletePoll: (state, name) => {
      console.log(state.poll);
      console.log(name);
      console.log(state.poll[name]);
      delete state.poll[name];
    },
    setLandingContent: (state, landingContent) => {
      state.landingContent = landingContent;
    },
    setProjectSettings: (state, projectSettings) => {
      state.projectSettings = projectSettings;
    },
    setVoicelog: (state, voicelog) => {
      state.voicelog = voicelog;
    },
    setStatus: (state, status) => {
      state.status = status;
    },
    setStartDateObj: (state, startDateObj) => {
      state.startDateObj = startDateObj;
    },
    setCompareOfferWait: (state, compareOfferWait) => {
      state.compareOfferWait = compareOfferWait;
    },
    setToggleTQIS: (state, toggleTQIS) => {
      state.toggleTQIS = toggleTQIS;
    },
    setRedoAll: (state, redoAll) => {
      state.redoAll = redoAll;
    },
    setMessage: (state, message) => {
      state.message = message;
    },
  },
  actions: {
    send_message: ({ dispatch }, { message, body }) => {
      send_message({ message, body });
    },
    fetchRecord: ({ dispatch }) => {
      return new Promise((resolve) => {
        dispatch("getRecord").then(() => {
          resolve();
        });
      });
    },
    openFlow: ({ commit, dispatch, getters }, request) => {
      return ApiService.post(getters.basePrepend + "record/openFlow", request)
        .then((response) => {
          console.log(response.data);
          commit("setRecord", response.data);
          dispatch("getProducts");
          dispatch("getSelectedProducts");
        })
        .catch((err) => {
          let message =
            err?.response?.data?.message ??
            "Something went wrong trying to open this record.";
          Notify.create({
            type: "negative",
            message: message,
            position: "top",
            timeout: 10000,
            progress: true,
          });
        });
    },
    hasSession: () => {
      return new Promise((resolve) => {
        // eslint-disable-next-line prettier/prettier
        ApiService
          .get("hasSession")
          .then((response) => {
          resolve(response.data);
        });
      });
    },
    setPendingRequests: ({ commit }, amount) => {
      commit("setPendingRequests", amount);
    },
    setViewMode: ({ commit }, viewMode) => {
      commit("setViewMode", viewMode);
    },
    setComponentSettings: ({ commit }, component_settings) => {
      commit("setComponentSettings", component_settings);
    },
    setProducts: ({ commit, getters }, products) => {
      commit("setProducts", products);
    },
    getSelectedProducts: ({ dispatch, getters }) => {
      ApiService.post(getters.basePrepend + "record/listProducts", {
        record_id: getters.record.id,
      }).then((response) => {
        console.log(response.data);
        dispatch("setSelectedProducts", response.data);
      });
    },
    setProduct: ({ dispatch, getters }, product) => {
      return new Promise((resolve) => {
        ApiService.post(
          getters.basePrepend + "record/setProduct",
          product
        ).then((response) => {
          resolve(dispatch("setSelectedProducts", response.data));
        });
      });
    },
    deleteProduct: ({ dispatch, getters }, product) => {
      ApiService.post(
        getters.basePrepend + "record/deleteProduct",
        product
      ).then((response) => {
        dispatch("setSelectedProducts", response.data);
      });
    },
    setSelectedProducts: ({ commit }, products) => {
      const selected = products.map((product) => {
        product.incentive = JSON.parse(product.incentive);
        product.product = JSON.parse(product.product);

        return product;
      });

      commit("setSelectedProducts", selected);
    },
    startPoll: ({ commit, dispatch, getters, state }, { name, restart }) => {
      commit("setPoll", { name });
    },
    stopPoll: ({ commit }, name) => {
      commit("deletePoll", name);
    },
    restartPolls: ({ getters, dispatch }) => {
      const polls = getters.poll;
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(polls)) {
        dispatch("startPoll", {
          id: value.intervalId,
          name: value.name,
          restart: true,
        });
      }
    },
    setCurrentStep: ({ commit, dispatch }, currentStep) => {
      commit("setCurrentStep", currentStep);
      dispatch("updateRecord", { key: "current_step", value: currentStep });
    },
    setUrl: ({ commit }, url) => {
      commit("setUrl", url);
    },
    setView: ({ commit }, view) => {
      commit("setView", view);
    },
    setExpandedTool: ({ commit }, expandedTool) => {
      commit("setExpandedTool", expandedTool);
    },
    setError: ({ commit }, error) => {
      commit("setError", error);
    },
    setLoading: ({ commit }, { key, value }) => {
      commit("setLoading", { key, value });
    },
    setStartDate: ({ commit }, startDate) => {
      commit("setStartDates", startDate);
    },
    setManualEan: ({ commit }, manualEan) => {
      commit("setManualEan", manualEan);
    },
    setProjectSpecificValue: (
      { commit, dispatch, getters },
      { key, value, validated = true }
    ) => {
      commit("setProjectSpecificValue", { key, value });
      if (validated !== true) {
        return;
      }
      return dispatch("updateRecord", {
        key: "Project_Specific",
        value: getters.record.Project_Specific,
      });
    },
    clearProjectSpecificValue: ({ commit, dispatch, getters }, { key }) => {
      commit("clearProjectSpecificValue", { key });
      dispatch("updateRecord", {
        key: "Project_Specific",
        value: getters.record.Project_Specific,
      });
    },
    setProjectSpecificValues: ({ commit, dispatch, getters }, { values }) => {
      for (const [key, value] of Object.entries(values)) {
        commit("setProjectSpecificValue", { key, value });
      }
      dispatch("updateRecord", {
        key: "Project_Specific",
        value: getters.record.Project_Specific,
      });
    },
    clearConnectEan: ({ dispatch }) => {
      dispatch("setProjectSpecificValues", {
        values: {
          gas: "",
          electricity: "",
          "EAN Gas": "",
          "EAN Elektra": "",
        },
      });
    },
    getRecord: ({ commit, getters }) => {
      return ApiService.post(getters.basePrepend + "record", {
        id: getters.record.id,
      }).then((response) => {
        commit("setRecord", response.data);
      });
    },
    clearPrevOffer: ({ commit, getters }) => {
      if (getters.viewMode) {
        return;
      }
      ApiService.post("clearPrevOffer", { record_id: getters.record.id }).then(
        (response) => {
          commit("setRecord", response.data);
        }
      );
    },
    getLandingContent: ({ commit }, { record_id, iv }) => {
      ApiService.post("multioffer/content", { record_id, iv }).then(
        (response) => {
          commit("setLandingContent", response.data);
        }
      );
    },
    getProjectSettings: ({ commit }, { id, iv, token }) => {
      return ApiService.post("multioffer/project/get", { id, iv, token }).then(
        (response) => {
          commit("setProjectSettings", response.data);
        }
      );
    },
    updateStatus: ({ commit, getters, dispatch }, { status, reason }) => {
      commit("updateRecord", { key: "Status_ID", value: status });
      commit("updateRecord", { key: "Status_Reden", value: status });
      if (getters.viewMode) {
        return;
      }

      return ApiService.post(getters.basePrepend + "record/update", {
        id: getters.record.id,
        Status_ID: status,
        Status_Reden: reason,
      }).then((response) => {
        commit("setRecord", response.data);
        dispatch("getStatus");
      });
    },
    getStatus: ({ commit, getters }) => {
      ApiService.post("status/get", {
        id: getters.record.status_id ?? getters.record.Status_ID,
      }).then((response) => {
        commit("setStatus", response.data[0]);
      });
    },
    updateRecord: (
      { commit, getters, dispatch },
      { key, value, validated = true }
    ) => {
      commit("updateRecord", { key, value });
      if (getters.viewMode) {
        return;
      }
      if (validated !== true) {
        return;
      }
      return ApiService.post(getters.basePrepend + "record/update", {
        id: getters.record.id,
        key,
        value,
      }).then((response) => {
        commit("setRecord", response.data);
        dispatch("getStatus");
      });
    },
    updateBAC: ({ dispatch, getters }, { body, task }) => {
      if (getters.viewMode) {
        return;
      }
      ApiService.post("update/bac", {
        record_id: getters.record.id,
        task,
        body,
        ...getters.rabbitRoute,
      }).then((response) => {
        dispatch("startPoll", { id: false, name: task, restart: false });
      });
    },
    sendMessage: (
      { dispatch, getters },
      { body, task, state = "", route = {} }
    ) => {
      if (getters.viewMode) {
        return;
      }
      ApiService.post("sendRabbitMessage", {
        record_id: getters.record.id,
        task,
        body,
        ...route,
        ...getters.rabbitRoute,
        state: state ?? true,
      }).then((response) => {
        if (!state) {
          dispatch("startPoll", { id: false, name: task, restart: false });
        }
      });
    },
    getCalculatedUsage: ({ getters, commit }, data) => {
      ApiService.post(getters.basePrepend + "getCalculatedUsage", data).then(
        (response) => {
          commit("setRecord", response.data);
        }
      );
    },
    getValidEmail: ({ commit, dispatch }, data) => {
      ApiService.post("getValidEmail", data)
        .then((response) => {
          commit("setEmailValid", response.data);
        })
        .finally(() => {
          dispatch("setLoading", {
            key: "email",
            value: false,
          });
        });
    },
    setValidEmail: ({ commit, dispatch }, data) => {
      commit("setEmailValid", data);
    },
    getValidIban: ({ commit, dispatch }, data) => {
      ApiService.post("getValidIban", data)
        .then((response) => {
          let iban = false;
          if (response.data.IBAN) {
            iban = response.data.IBAN;
            dispatch("updateRecord", {
              key: "IBAN",
              value: iban,
            });
          }
          commit("setIbanValid", iban);
        })
        .finally(() => {
          dispatch("setLoading", {
            key: "iban",
            value: false,
          });
        });
    },
    setValidIban: ({ commit }, iban) => {
      commit("setIbanValid", iban);
    },
    getProducts: ({ commit, getters, dispatch }) => {
      if (getters.viewMode) {
        return;
      }
      let project = getters.steamVelden.project;
      if (
        getters.projectSpecific?.CampagneLeading &&
        getters.projectSpecific?.CampagneLeading == "true"
      ) {
        project = getters.steamVelden.campagne;
      }
      ApiService.post(getters.basePrepend + "products", {
        project,
        token: getters.projectSpecific["Token"],
        record_id: getters.record.id,
      }).then((response) => {
        if (response.data.products) {
          dispatch("setProducts", response.data);
        }
      });
    },
    verifyOffer: ({ getters, dispatch }) => {
      if (getters.viewMode) {
        return;
      }
      let project = getters.steamVelden.project;
      if (
        getters.projectSpecific?.CampagneLeading &&
        getters.projectSpecific?.CampagneLeading == "true"
      ) {
        project = getters.steamVelden.campagne;
      }
      Loading.show();
      return ApiService.post(getters.basePrepend + "offer/verify", {
        project,
        record_id: getters.record.id,
      }).then((response) => {
        if (response.data.verified) {
          dispatch("incrementCurrentStep");

          Loading.hide();
          return;
        }

        dispatch("send_message", { message: "get_offers" });
      });
    },
    verifyAllOffers: ({ getters, dispatch }) => {
      if (getters.viewMode) {
        return;
      }
      let project = getters.steamVelden.project;
      if (
        getters.projectSpecific?.CampagneLeading &&
        getters.projectSpecific?.CampagneLeading == "true"
      ) {
        project = getters.steamVelden.campagne;
      }
      Loading.show();
      return ApiService.post(getters.basePrepend + "offer/verify", {
        project,
        record_id: getters.record.id,
      }).then((response) => {
        if (response.data.verified) {
          dispatch("incrementCurrentStep");

          Loading.hide();
          return;
        }

        dispatch("send_message", { message: "get_all_offers" });
      });
    },
    getContent: ({ getters, commit }, data = {}) => {
      if (getters.viewMode) {
        return;
      }

      const body = {
        project: getters.steamVelden.project,
        project_id: getters.record.project_id,
        record_id: getters.record.id,
        useTemplateEngine: data.useTemplateEngine,
      };

      if (data.text) {
        body.text = data.text;
      }
      return ApiService.post(
        getters.basePrepend +
          "content" +
          (getters.view == "multioffer" ? "/voicelog" : ""),
        body
      ).then((response) => {
        commit("setVoicelog", response.data);
      });
    },
    setSearchedAddress: ({ commit, dispatch }, value) => {
      commit("setSearchedAddress", value);
    },
    get_house_number_extensions: ({ dispatch, getters }) => {
      ApiService.post("sendRabbitMessage", {
        record_id: getters.record.id,
        task: "get_house_number_extensions",
        ...getters.rabbitRoute,
        state: false,
        body: {
          record_PID: getters.record.id,
          postcode: getters.record.Postcode,
          house_number: getters.record.Huisnummer,
          house_number_extension: getters.record.HuisnummerToev ?? "",
        },
      }).then((response) => {
        dispatch("startPoll", {
          id: false,
          name: "get_house_number_extensions",
          restart: false,
        });
      });
    },
    // start_bac_flow: ({ dispatch, getters }, state = "") => {
    //   dispatch("setManualEan", false);
    //   ApiService.post("update/bac", {
    //     record_id: getters.record.id,
    //     task: "start_bac_flow",
    //     state: state ?? true,
    //     body: {
    //       postcode: getters.record.Postcode,
    //       house_number: getters.record.Huisnummer,
    //       house_number_extension: getters.record.HuisnummerToev ?? "",
    //       street: getters.record.Straat,
    //       city: getters.record.Woonplaats,
    //       record_PID: getters.record.id,
    //     },
    //   }).then((response) => {
    //     dispatch("startPoll", {
    //       id: false,
    //       name: "start_bac_flow",
    //       restart: false,
    //     });
    //   });
    // },
    get_installationdetails: ({ dispatch }) => {
      dispatch("send_message", { message: "get_installationdetails" });
    },
    get_offers: ({ dispatch, getters }) => {
      if (!Loading.isActive) {
        Loading.show();
      }
      ApiService.post("sendRabbitMessage", {
        record_id: getters.record.id,
        task: "get_offers",
        ...getters.rabbitRoute,
        state: false,
        body: {
          Huisnummer: getters.record.Huisnummer,
          HuisnummerToev: getters.record.HuisnummerToev,
          Postcode: getters.record.Postcode,
          Achternaam: getters.record.Achternaam,
          Segment: getters.projectSpecific["Segment"],
          SwitchOrMove: getters.projectSpecific["SwitchOrMove"],
          campaignid:
            getters.projectSpecific["selectedProduct"].product.campaignid,
          incentive:
            getters.projectSpecific["selectedProduct"].incentive.IncentiveId,
          product_titel:
            getters.projectSpecific["selectedProduct"].product.selectielijst,
          start_date: getters.projectSpecific["StartDate"],
          duur: getters.projectSpecific["selectedProduct"].product.duur,
          verbruikelectra: getters.projectSpecific["verbruik electra"] ?? "",
          verbruikelectra2: getters.projectSpecific["verbruik electra 2"] ?? "",
          verbruikelectrateruglevering:
            getters.projectSpecific["verbruik electra teruglevering"] ?? "",
          verbruikgas: getters.projectSpecific["verbruik gas"] ?? "",
          verblijfsFunctie: getters.projectSpecific["verblijfsFunctie"],
          Projecttype: getters.projectSpecific["Projecttype"],
          Userid: getters.record.Userid,
          record_PID: getters.record.id,
          MeterType: getters.projectSpecific["type meter"],
          Project: getters.steamVelden["project"],
          projectSpecificParts: {
            product:
              getters.projectSpecific["selectedProduct"].product.productid,
            incentive:
              getters.projectSpecific["selectedProduct"].incentive.IncentiveId,
            duur: getters.projectSpecific["selectedProduct"].product.duur,
          },
          steamParts: {
            campagne: getters.steamVelden["campagne"],
            campaignid:
              getters.projectSpecific["selectedProduct"].product.campaignid,
            project: getters.steamVelden["project"],
          },
        },
      }).then((response) => {
        dispatch("startPoll", {
          id: false,
          name: "get_offers",
          restart: false,
        });
      });
    },
    // select_proposition: ({ dispatch, getters }, values) => {
    //   const duration =
    //     getters.projectSpecific["selectedProduct"].product.duur.split(" ");
    //   ApiService.post("update/bac", {
    //     record_id: getters.record.id,
    //     task: "select_proposition",
    //     state: true,
    //     body: {
    //       record_PID: getters.record.id,
    //       campaign_id:
    //         getters.projectSpecific["selectedProduct"].product.campaignid,
    //       incentive_id:
    //         getters.projectSpecific["selectedProduct"].incentive.IncentiveId ==
    //         "Geen"
    //           ? getters.projectSpecific["selectedProduct"].product.incentiveidno
    //           : getters.projectSpecific["selectedProduct"].incentive
    //               .IncentiveId,
    //       duration_in_years: duration[0],
    //     },
    //   });
    // },
    // save_budget_bill_amount: ({ dispatch, getters }, values) => {
    //   ApiService.post("update/bac", {
    //     record_id: getters.record.id,
    //     task: "save_budget_bill_amount",
    //     state: true,
    //     body: {
    //       record_PID: getters.record.id,
    //       budget_bill_amount:
    //         getters.projectSpecific["voorschotbedrag"] ??
    //         getters.projectSpecific["_ToAmMonth_"],
    //     },
    //   });
    // },
    // save_personal_details: ({ dispatch, getters }, values) => {
    //   ApiService.post("update/bac", {
    //     record_id: getters.record.id,
    //     task: "save_personal_details",
    //     state: true,
    //     body: {
    //       record_PID: getters.record.id,
    //       initials: getters.record.Initialen,
    //       last_name: getters.record.Achternaam,
    //       gender: getters.record.Geslacht == "M" ? "male" : "female",
    //       date_of_birth: getters.Gebdat,
    //     },
    //   });
    // },
    // save_correspondence: ({ dispatch, getters }, preferred_channel) => {
    //   ApiService.post("update/bac", {
    //     record_id: getters.record.id,
    //     task: "save_correspondence",
    //     state: true,
    //     body: {
    //       record_PID: getters.record.id,
    //       preferred_channel,
    //       email: getters.record.Email,
    //       phone: getters.record.Telefoon,
    //     },
    //   });
    // },
    // save_payment_details: ({ dispatch, getters }, values) => {
    //   ApiService.post("update/bac", {
    //     record_id: getters.record.id,
    //     task: "save_payment_details",
    //     state: true,
    //     body: {
    //       record_PID: getters.record.id,
    //       iban: getters.record.IBAN,
    //       use_correspondence_address: true, //! if use_correspondence_address is false, then Invoice address needs to be provided.
    //       incoming_payment_method:
    //         getters.record.betaalwijze == "handmatig"
    //           ? "manual"
    //           : "direct_debit",
    //     },
    //   });
    // },
    verifyTQIS: ({ dispatch, getters }, verification) => {
      ApiService.post(getters.basePrepend + "tqis/verify", {
        record_id: getters.record.id,
        code: verification,
      }).then((response) => {
        if (response.data.error) {
          Notify.create({
            type: "negative",
            message: response.data.message,
            position: "top",
            progress: true,
            timeout: 7000,
          });

          return;
        }

        Notify.create({
          type: "positive",
          message: "De ingevoerde code is correct",
          position: "top",
          progress: true,
          timeout: 3000,
        });

        dispatch("incrementCurrentStep");

        dispatch("updateStatus", {
          status: 29,
          reason: getters.voicelog,
        });
      });
    },
    toggleTQIS: ({ getters, commit }) => {
      ApiService.post(getters.basePrepend + "tqis/toggle").then((response) => {
        commit("setToggleTQIS", response.data);
      });
    },
    removeTQIS: ({ getters }) => {
      ApiService.post(getters.basePrepend + "tqis/remove", {
        record_id: getters.record.id,
      });
    },
    sendOffers: ({ getters }) => {
      return ApiService.post(getters.basePrepend + "record/sendOffers", {
        record_id: getters.record.id,
      });
    },
    incrementCurrentStep: ({ dispatch, getters }) => {
      dispatch("setCurrentStep", getters.record.current_step + 1);
    },
    decrementCurrentStep: ({ dispatch, getters }) => {
      if (getters.record.current_step <= 1) {
        return;
      }
      dispatch("setCurrentStep", getters.record.current_step - 1);
    },
    setStartDateObj: ({ commit }, startDateObj) => {
      commit("setStartDateObj", startDateObj);
    },
    checkSample: ({ getters }) => {
      return ApiService.post(getters.basePrepend + "record/sample/check", {
        id: getters.record.id,
      });
    },
    setCompareOfferWait: ({ commit }, compareOfferWait) => {
      commit("setCompareOfferWait", compareOfferWait);
    },
    setRedoAll: ({ commit }, redoAll) => {
      commit("setRedoAll", redoAll);
    },
    setMessage: ({ commit }, message) => {
      commit("setMessage", message);
    },
    NLIcreateAppointment: ({ getters }, id) => {
      return ApiService.post(
        getters.basePrepend + "Partner/NLI/makeAppointment",
        id
      );
    },
  },
  getters: {
    error: (state) => state.error,
    loading: (state) => state.loading,
    record: (state) => state.record,
    url: (state) => state.url,
    viewMode: (state) => state.viewMode,
    view: (state) => state.view,
    rabbitRoute: (state) => {
      switch (state.view) {
        case "energyportal":
          return {
            flow: "bac",
          };
        case "retention":
          return {
            flow: "sac",
          };
      }
      return {};
    },
    basePrepend: (state) => {
      const noPrependList = ["energyportal", "retention"];
      if (noPrependList.includes(state.view)) {
        return "";
      }

      return state.view + "/";
    },
    voicelog: (state) =>
      state.record.Prevoicelog_tekst ??
      state?.voicelog?.Voicelog ??
      state.voicelog ??
      "",
    offerText: (state) => state.record.Meekijk_tekst ?? "",
    termsText: (state) => state.record.PDF_tekst ?? "",
    addressOptions: (state, getters) => getters.projectSpecific.AddressOptions,
    Gebdat: (state, getters) => getters.projectSpecific.Gebdat,
    products: (state) => state.products,
    selectedProducts: (state) => state.selectedProducts,
    projectSpecific: (state) => JSON.parse(state.record.Project_Specific) || {},
    steamVelden: (state) => JSON.parse(state.record.Steam_Velden) || {},
    poll: (state) => state.poll,
    manualEan: (state) => state.manualEan,
    pollLimit: (state) => state.pollLimit,
    expandedTool: (state) => state.expandedTool,
    startDate: (state) => state.startDate,
    emailValid: (state) => state.emailValid,
    ibanValid: (state) => state.ibanValid,
    pendingRequests: (state) => state.pendingRequests,
    searchedAddress: (state) => state.searchedAddress,
    landingContent: (state) => state.landingContent,
    projectSettings: (state) => state.projectSettings,
    component_settings: (state) => state.component_settings,
    installationdetails: (state, getters) => {
      const installationdetails = {
        gas: getters.projectSpecific.gas ?? [],
        electricity: getters.projectSpecific.electricity ?? [],
      };

      return installationdetails;
    },
    status: (state) => state.status,
    startDateObj: (state) => state.startDateObj,
    compareOfferWait: (state) => state.compareOfferWait,
    toggleTQIS: (state) => state.toggleTQIS,
    redoAll: (state) => state.redoAll,
    message: (state) => state.message,
  },
  modules: {
    bac,
  },
  plugins: process.env.NODE_ENV !== "production" ? [createLogger] : [],
});

store.subscribe((mutation, state) => {
  console.log(mutation);
  if (listening && (store.getters.record.id ?? 0) !== listeningRecordId) {
    echo.leave("record-" + listeningRecordId);
    echo.leave("record-" + listeningRecordId + "-get_products");
    listening = false;
  }
  if ((store.getters.record.id ?? false) && !listening) {
    echo
      .channel("record-" + store.getters.record.id)
      .listen("PollResponseEvent", (response) => {
        store.dispatch("stopPoll", response.name);
        store.dispatch("fetchRecord").then(() => {
          if (
            Loading.isActive &&
            (!store.getters.projectSpecific?.compareOffersResponse?.Message ||
              store.getters.projectSpecific?.compareOffersResponse?.Message !=
                "Waiting")
          ) {
            Loading.hide();
          }
          if (response.state == 2 && response.name == "get_city") {
            Notify.create({
              type: "warning",
              message: "Er is geen adres gevonden.",
              position: "top",
              timeout: 10000,
              progress: true,
            });
          }
          if (response.state == 2 && response.name == "get_summary") {
            Notify.create({
              type: "warning",
              message:
                "Het is meer dan 14 dagen geleden dat je bent begonnen met dit record. Check de gegevens opnieuw om af te ronden.",
              position: "top",
              timeout: 10000,
              progress: true,
            });
          }
          if (
            response.state == 2 &&
            (response.name == "get_installationdetails" ||
              response.name == "start_bac_flow")
          ) {
            store.dispatch("setManualEan", true);
            store.dispatch("clearConnectEan");
          }
          if (
            response.state == 2 &&
            response.name == "get_expected_consumption"
          ) {
            Notify.create({
              type: "negative",
              message: "Er is geen verbruik gevonden",
              position: "top",
              timeout: 5000,
            });
            store.dispatch("setProjectSpecificValues", {
              values: {
                verbruikBerekeningsType: "",
                SuccesfullGPV: "Nee",
              },
            });
          }
          if (
            response.state == 1 &&
            response.name == "get_offers" &&
            (!store.getters.projectSpecific?.compareOffersResponse?.Message ||
              store.getters.projectSpecific?.compareOffersResponse?.Message !=
                "Waiting")
          ) {
            Notify.create({
              type: "positive",
              message: "Prijzen opgehaald",
              position: "top",
              timeout: 2000,
            });
            if (store.getters.view == "multioffer") {
              store.dispatch("getSelectedProducts");
            }
          }
          if (
            response.state == 2 &&
            response.name == "get_offers" &&
            (!store.getters.projectSpecific?.compareOffersResponse?.Message ||
              store.getters.projectSpecific?.compareOffersResponse?.Message !=
                "Waiting")
          ) {
            Notify.create({
              type: "negative",
              message: "Er zijn geen prijzen bekend voor dit product",
              position: "top",
              timeout: 5000,
            });
          }
          if (
            response.state == 2 &&
            response.name == "get_offers" &&
            store.getters.projectSpecific?.compareOffersResponse?.Message ==
              "NoGo"
          ) {
            let message = `De tarieven van het geselecteerde product zijn gewijzigd. Het indicatief termijnbedrag is veranderd van €${store.getters.projectSpecific.compareOffersResponse.Termijn_old} naar €${store.getters.projectSpecific.compareOffersResponse.Termijn_new}.`;
            if (
              store.getters.projectSpecific?.termijnbedragaanpassen &&
              store.getters.projectSpecific?.termijnbedragaanpassen == "Ja"
            ) {
              message = `De tarieven van het geselecteerde product zijn gewijzigd. Het indicatief termijnbedrag is veranderd van €${store.getters.projectSpecific.compareOffersResponse.Termijn_old} naar €${store.getters.projectSpecific.compareOffersResponse.Termijn_new}. Het aangepaste termijnbedrag van €${store.getters.projectSpecific.voorschotbedrag} blijft staan.`;
            }
            Dialog.create({
              title: "Alert",
              message,
            });
          } else if (
            response.name == "get_offers" &&
            store.getters.projectSpecific?.compareOffersResponse?.Message ==
              "Go"
          ) {
            let compareOffersResponse =
              store.getters.projectSpecific?.compareOffersResponse;
            compareOffersResponse.Message = "";
            store
              .dispatch("setProjectSpecificValue", {
                key: "compareOffersResponse",
                value: compareOffersResponse,
              })
              .then(() => {
                if (
                  store.getters.compareOfferWait === null ||
                  store.getters.compareOfferWait === false
                ) {
                  store.dispatch("incrementCurrentStep");
                }
                if (store.getters.compareOfferWait === true) {
                  store.dispatch("setCompareOfferWait", false);
                }
              });
          }
          if (
            response.state == 2 &&
            response.name == "get_housenumber_extensions"
          ) {
            Notify.create({
              type: "negative",
              message: "Er is geen adres bekend met deze combinatie",
              position: "top",
              timeout: 5000,
            });
          }
          if (response.error && response.name == "start_sac_flow") {
            Notify.create({
              type: "negative",
              message: response.error,
              position: "top",
              timeout: 5000,
            });
            Dialog.create({
              title: "Error",
              message: response.error,
              persistent: true,
              cancel: false,
            }).onOk(() => {
              if (!cc_Enabled.value) {
                return;
              }
              cc_GotoPage("LaadRecord");
            });
          }
          if (response.next) {
            store.dispatch("send_message", { message: response.next });
          } else if (
            store.getters.redoAll &&
            store.getters.view == "retention" &&
            store.record?.Steam_Velden?.project == "EESP281911" &&
            response.name == "get_current_consumption"
          ) {
            store.dispatch("send_message", { message: "save_consumption" });
            setTimeout(() => {
              store.dispatch("send_message", {
                message: "select_proposition",
              });
              setTimeout(() => {
                store.dispatch("setRedoAll", false);
                Loading.hide();
                Notify({
                  type: "positive",
                  message: store.getters.message,
                  position: "top",
                  timeout: 10000,
                  progress: true,
                });
              }, 3000);
            }, 3000);
          }
          switch (response.name) {
            case "get_current_consumption":
              store.dispatch("setLoading", {
                key: "address",
                value: false,
              });
              break;
            case "get_installationdetails":
            case "get_city":
              store.dispatch("setLoading", {
                key: "address",
                value: false,
              });
              break;
            case "get_expected_consumption":
              store.dispatch("setLoading", {
                key: "gpv",
                value: false,
              });
              break;
          }
          console.log(response);
        });
      });
    echo
      .channel("record-" + store.getters.record.id + "-get_products")
      .listen("DirectResponseEvent", (response) => {
        map_products(response);
      });
    listening = !listening;
    listeningRecordId = store.getters.record.id;
  }
});

export default store;
