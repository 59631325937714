import Loading from 'quasar/src/plugins/Loading.js';import date from 'quasar/src/utils/date.js';;
import store from "../../store/index";
import Notify from 'quasar/src/plugins/Notify.js';;

const get_brand = () => {
  let brand = store.getters.projectSpecific["Energieleverancier"] ?? "essent";

  if (
    store.getters.projectSpecific["Energieleverancier"] &&
    store.getters.projectSpecific["Energieleverancier"] == "ED"
  ) {
    brand = "energiedirect";
  }

  if (store.getters.view === "multioffer") {
    brand = "multioffer";
  }

  return brand.toLowerCase();
};

const get_city = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { route: { brand: get_brand() } },
    task: "get_city",
    state: false,
    body: {
      record_PID: store.getters.record.id,
      postcode: store.getters.record.Postcode,
      house_number: store.getters.record.Huisnummer,
      house_number_extension: store.getters.record.HuisnummerToev ?? "",
    },
  });
};

const get_house_number_extensions = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "get_housenumber_extensions",
    state: false,
    body: {
      record_PID: store.getters.record.id,
      postcode: store.getters.record.Postcode,
      house_number: store.getters.record.Huisnummer,
      house_number_extension: store.getters.record.HuisnummerToev ?? "",
    },
  });
};

const start_bac_flow = () => {
  store.dispatch("setManualEan", false, { root: true });
  if (
    store.getters.record.Woonplaats == null ||
    store.getters.record.Straat == null
  ) {
    store.dispatch("fetchRecord").then(() => {
      if (
        store.getters.record.Woonplaats == null ||
        store.getters.record.Straat == null
      ) {
        Notify.create({
          type: "negative",
          message:
            "Straat en of woonplaats kon niet juist worden opgeslagen, graag opnieuw naar het adres zoeken.",
          position: "top",
          timeout: 5000,
        });
      } else {
        store.dispatch("sendMessage", {
          record_id: store.getters.record.id,
          route: { brand: get_brand() },
          task: "start_bac_flow",
          state: false,
          body: {
            postcode: store.getters.record.Postcode,
            house_number: store.getters.record.Huisnummer,
            house_number_extension: store.getters.record.HuisnummerToev ?? "",
            street: store.getters.record.Straat,
            city: store.getters.record.Woonplaats,
            record_PID: store.getters.record.id,
          },
        });
      }
    });
  } else {
    store.dispatch("sendMessage", {
      record_id: store.getters.record.id,
      route: { brand: get_brand() },
      task: "start_bac_flow",
      state: false,
      body: {
        postcode: store.getters.record.Postcode,
        house_number: store.getters.record.Huisnummer,
        house_number_extension: store.getters.record.HuisnummerToev ?? "",
        street: store.getters.record.Straat,
        city: store.getters.record.Woonplaats,
        record_PID: store.getters.record.id,
      },
    });
  }
};

const get_installationdetails = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "get_installationdetails",
    state: false,
    body: {
      record_PID: store.getters.record.id,
      postcode: store.getters.record.Postcode,
      house_number: store.getters.record.Huisnummer,
      house_number_extension: store.getters.record.HuisnummerToev ?? null,
    },
  });
};

const save_additional_information = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_additional_information",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      residential: store.getters.projectSpecific.residential,
      contract_with_another_supplier:
        store.getters.projectSpecific.contract_with_another_supplier,
      contact_date:
        date.formatDate(store.getters?.Email_verzonden ?? Date.now(), "YYYY-MM-DD"),
    },
  });
};

const save_ean_details = () => {
  var eans = [];
  if (store.getters.projectSpecific["EAN Elektra"]) {
    eans.push(store.getters.projectSpecific["EAN Elektra"]);
  }
  if (store.getters.projectSpecific["EAN Gas"]) {
    eans.push(store.getters.projectSpecific["EAN Gas"]);
  }
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_ean_details",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      connect_ean: eans,
    },
  });
};

const get_expected_consumption = (residents) => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "get_expected_consumption",
    state: false,
    body: {
      postcode: store.getters.record.Postcode,
      house_number: store.getters.record.Huisnummer,
      residents: residents ?? store.getters.projectSpecific["NumberOfPersons"],
      vacancy: false,
    },
  });
};

const save_consumption = () => {
  let body = {
    record_PID: store.getters.record.id,
    payload: [
      {
        energy_type: "electricity",
        standard_annual_usages: [
          {
            direction_tariff: "supply_low",
            reading: store.getters.projectSpecific["verbruik electra 2"] || 0,
          },
          {
            direction_tariff: "supply_normal",
            reading: store.getters.projectSpecific["verbruik electra"] || 0,
          },
          {
            direction_tariff: "return_supply_low",
            reading: 0,
          },
          {
            direction_tariff: "return_supply_normal",
            reading:
              store.getters.projectSpecific["verbruik electra teruglevering"] ||
              0,
          },
        ],
      },
    ],
  };
  if (store.getters.projectSpecific["verbruik gas"]) {
    body.payload.push({
      energy_type: "gas",
      standard_annual_usages: [
        {
          direction_tariff: "supply_normal",
          reading: store.getters.projectSpecific["verbruik gas"] || 0,
        },
      ],
    });
  }

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_consumption",
    state: true,
    body,
  });
};

const save_start_date = ({ electricity, gas }) => {
  const start_date_elec = electricity ?? store.getters.startDate.electricity;
  const start_date_gas = gas ?? store.getters.startDate.gas;

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_start_date",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      dates: [
        {
          energy_type: "electricity",
          start_date: date.formatDate(start_date_elec, "YYYY-MM-DD"),
        },
        {
          energy_type: "gas",
          start_date: date.formatDate(start_date_gas, "YYYY-MM-DD"),
        },
      ],
    },
  });
};

const save_start_date_and_select_proposition = () => {
  //? (｡╯︵╰｡) **cking dates
  let startDate = store.getters.projectSpecific["StartDate"];
  const tmp = startDate.split("-");
  const newDate = new Date(tmp[2], tmp[1] - 1, tmp[0]);

  const start_date_elec = newDate ?? store.getters.startDate.electricity;
  const start_date_gas = newDate ?? store.getters.startDate.gas;
  const duration =
    store.getters.projectSpecific["selectedProduct"].product.duur.split(" ");

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_start_date_and_select_proposition",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      campaign_id:
        store.getters.projectSpecific["selectedProduct"].product.campaignid,
      incentive_id:
        store.getters.projectSpecific["selectedProduct"].incentive
          .IncentiveId == "Geen"
          ? store.getters.projectSpecific["selectedProduct"].product
              .incentiveidno
          : store.getters.projectSpecific["selectedProduct"].incentive
              .IncentiveId,
      duration_in_years: duration[0],
      dates: [
        {
          energy_type: "electricity",
          start_date: date.formatDate(start_date_elec, "YYYY-MM-DD"),
        },
        {
          energy_type: "gas",
          start_date: date.formatDate(start_date_gas, "YYYY-MM-DD"),
        },
      ],
    },
  });
};

const get_offers = (selected_product = null) => {
  if (!Loading.isActive && !store.getters.viewMode) {
    Loading.show();
  }
  if (selected_product === null) {
    selected_product = store.getters.projectSpecific["selectedProduct"];
  }
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    task: "get_offers",
    state: false,
    route: {
      branch: selected_product.product.segment ?? "b2c",
      brand: selected_product.product.brand ?? get_brand(),
      product: selected_product.product.type ?? "energy",
    },
    body: {
      Huisnummer: store.getters.record.Huisnummer,
      HuisnummerToev: store.getters.record.HuisnummerToev,
      Postcode: store.getters.record.Postcode,
      Achternaam: store.getters.record.Achternaam,
      Segment: store.getters.projectSpecific["Segment"],
      SwitchOrMove: store.getters.projectSpecific["SwitchOrMove"],
      campaignid: store.getters.record.External_campagne,
      incentive: selected_product.incentive.IncentiveId,
      incentiveName: selected_product.incentive.incentiveName ?? "Geen",
      incentiveReturn: selected_product.incentive.incentiveReturn ?? 0,
      product_titel: selected_product.product.selectielijst,
      start_date: store.getters.projectSpecific["StartDate"],
      type_id: selected_product.type_id,
      product_id: selected_product.product.productid,
      duur: selected_product.product.duur,
      verbruikelectra: store.getters.projectSpecific["verbruik electra"] ?? "",
      verbruikelectra2:
        store.getters.projectSpecific["verbruik electra 2"] ?? "",
      verbruikelectrateruglevering:
        store.getters.projectSpecific["verbruik electra teruglevering"] ?? "",
      verbruikgas: store.getters.projectSpecific["verbruik gas"] ?? "",
      verblijfsFunctie: store.getters.projectSpecific["verblijfsFunctie"],
      Projecttype: store.getters.projectSpecific["Projecttype"],
      Userid: store.getters.record.Userid,
      record_PID: store.getters.record.id,
      MeterType: store.getters.projectSpecific["type meter"],
      Project: store.getters.steamVelden["project"],
      projectSpecificParts: {
        product: selected_product.product.productid,
        incentive: selected_product.incentive.IncentiveId,
        incentiveName: selected_product.incentive.incentiveName ?? "Geen",
        incentiveReturn: selected_product.incentive.incentiveReturn ?? 0,
        duur: selected_product.product.duur,
        Energieleverancier:
          store.getters.projectSpecific["Energieleverancier"] ?? "Essent",
      },
      steamParts: {
        campagne: store.getters.steamVelden["campagne"],
        campaignid: selected_product.product.campaignid,
        project: store.getters.steamVelden["project"],
      },
    },
  });
};

const get_all_offers = () => {
  const selected_products = store.getters.selectedProducts;
  selected_products.forEach((selected_product) => {
    if ((selected_product.product.length ?? []) === 0) return;

    get_offers(selected_product);
  });
};

const select_proposition = () => {
  const duration =
    store.getters.projectSpecific["selectedProduct"].product.duur.split(" ");
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "select_proposition",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      campaign_id:
        store.getters.projectSpecific["selectedProduct"].product.campaignid,
      incentive_id:
        store.getters.projectSpecific["selectedProduct"].incentive
          .IncentiveId == "Geen"
          ? store.getters.projectSpecific["selectedProduct"].product
              .incentiveidno
          : store.getters.projectSpecific["selectedProduct"].incentive
              .IncentiveId,
      duration_in_years: duration[0],
    },
  });
};

const save_budget_bill_amount = () => {
  let amount = store.getters.projectSpecific["voorschotbedrag"];
  let month = store.getters.projectSpecific["_ToAmMonth_"];
  if (typeof amount == "string" && amount.includes(",")) {
    amount = amount.replace(/,/g, ".");
  }
  if (typeof month == "string" && month.includes(",")) {
    month = month.replace(/,/g, ".");
  }
  let budgetBillAmount =
    (store.getters.projectSpecific["Termijnbedragaanpassen"] == "Ja"
      ? Math.round(amount)
      : null) || Math.round(month);
  if (budgetBillAmount < 10) {
    budgetBillAmount = 10;
  }
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_budget_bill_amount",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      budget_bill_amount: budgetBillAmount,
    },
  });
};

const save_personal_details = () => {
  const gebDat = store.getters.Gebdat;
  const tmp = gebDat.split("-");
  const newDate = new Date(tmp[2], tmp[1] - 1, tmp[0]);

  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_personal_details",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      initials: store.getters.record.Initialen,
      last_name: store.getters.record.Achternaam,
      gender: store.getters.record.Geslacht == "M" ? "male" : "female",
      date_of_birth: date.formatDate(newDate, "YYYY-MM-DD"),
    },
  });
};

const save_correspondence = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_correspondence",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      preferred_channel:
        store.getters.projectSpecific["preferred_channel"] ?? "email",
      email: store.getters.record.Email,
      phone: store.getters.record.Telefoon,
    },
  });
};

const save_payment_details = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "save_payment_details",
    state: true,
    body: {
      record_PID: store.getters.record.id,
      iban: store.getters.record.IBAN,
      use_correspondence_address: true, //! if use_correspondence_address is false, then Invoice address needs to be provided.
      incoming_payment_method:
        store.getters.record.betaalwijze == "handmatig"
          ? "manual"
          : "direct_debit",
    },
  });
};

const get_summary = () => {
  store.dispatch("sendMessage", {
    record_id: store.getters.record.id,
    route: { brand: get_brand() },
    task: "get_summary",
    state: false,
    body: {
      record_PID: store.getters.record.id,
    },
  });
};

export {
  get_house_number_extensions,
  start_bac_flow,
  get_installationdetails,
  save_additional_information,
  save_ean_details,
  get_expected_consumption,
  save_consumption,
  save_start_date,
  save_start_date_and_select_proposition,
  get_offers,
  get_all_offers,
  select_proposition,
  save_budget_bill_amount,
  save_personal_details,
  save_correspondence,
  save_payment_details,
  get_city,
  get_summary,
};
